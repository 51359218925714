import axios from "axios";
import { useloggedUserInfo } from "./stores/UserInfo";
// Our APIGATEWAY URL

const baseDomain = process.env.VUE_APP_API_URL; 
const baseDomainAsset = process.env.VUE_APP_COMMON_API; 
const baseURL = `${baseDomain}`;
const commonURL = `${baseDomainAsset}`;
const instance = axios.create({
  baseURL,
});
const instanceCommon = axios.create({
    baseURL:commonURL,
  });
instance.interceptors.request.use(
    (config) => {
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] =config.url=='/SaveGroupAssign' ||config.url=='/AddProductType' || config.url =='/AddAssetWizard'?"multipart/form-data":  "application/json;charset=UTF-8";      
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
instanceCommon.interceptors.request.use(
  
    (config) => {
        
        const token = useloggedUserInfo().GetUserInfo.Token;
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;          
            config.headers["content-type"] = "application/json;charset=UTF-8";           
            config.headers['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default {instance,instanceCommon};
