import http from "../http-common";
class DataService {
  GetContextMenu(params) {
    return http.instanceCommon.get("/Common/GetContextMenu?" + params);
  }
  GetLanguageList() {
    return http.instanceCommon.get("/Common/GetLanguageList");
  }
  GetFullMenu() {
    return http.instanceCommon.get("/Common/GetFullMenu");
  }
  async GetConfigurationValueForCompanyByKey(param) {
    return await http.instanceCommon.get(
      "/Common/GetConfigurationValueForCompanyByKey?" + param
    );
  }
  async GetHideContextMenuConfig() {
    return await http.instanceCommon.get("/Common/GetHideContextMenuConfig");
  }
  async SaveHideContextMenuConfig(param) {
    return await http.instanceCommon.post(
      "/Common/SaveHideContextMenuConfig?" + param
    );
  }
  async GetModuleMasterIdByModuleName(params) {
    return await http.instanceCommon.get(
      "/Common/GetModuleMasterIdByModuleName?" + params
    );
  }
  SaveTag(params) {
    return http.instance.post("/SaveTag", { postString: params });
  }
  // GetTagsByModuleId(params){
  //   return http.instanceCommon.get("/Tag/GetTagsByModuleId?"+params);
  // }
  GetFavDashboard() {
    return http.instanceCommon.get("/Common/GetFavDashboard");
  }
  CheckPrivilege(params) {
    return http.instanceCommon.get("/Common/HasPermission?" + params);
  }
  async ApprovalRuleListing(param) {
    return await http.instance.get("/ApprovalRule/GetApprovalRuleList?" + param);
  }
  async ApprovalGroupListing(param) {
    return await http.instance.get("/ApprovalGroup/GetApprovalGroupList?" + param);
  }
  async LoadUsersByCompanyId(param) {
    return await http.instance.get("/ApprovalRule/LoadUsersByCompanyId?"+ param);
  }
  async GetApprovalChainsByCompanyId() {
    return await http.instance.get("/ApprovalRule/GetApprovalChainsByCompanyId?");
  }
  async GetApprovalTypes(param) {
    return await http.instance.get("/ApprovalRule/GetApprovalTypes?"+ param );
  }
  async UpdateStatus(param) {
    return await http.instance.post("/ApprovalRule/UpdateStatus?"+ param );
  }
  async ApprovalChainListing(param) {    
    return await http.instance.get("/ApprovalChain/GetApprovalChainList?" + param);
  }
  async DeleteRule(param) {   
    return await http.instance.post("/ApprovalRule/DeleteRule?" + param);
  }
  async DeleteGroup(param) {    
    return await http.instance.post("/ApprovalGroup/DeleteGroup?" + param);
  }
  async AddGroup(param) {  
    return await http.instance.post("/ApprovalGroup/AddGroup?" , { postString: param });
  }
  async GetById(param) {
    return await http.instance.get("/ApprovalGroup/GetById?" + param);
  }
  async GetProjectListing(param) {
    return await http.instance.get("/ApprovalRule/GetProjectList?" + param);
  }
  async GetUsersByProjectId(param) {
    return await http.instance.get("/ApprovalRule/LoadUsersByProjectId?" + param);
  }
  async GetLocationByCompanyId(param) {
    return await http.instance.get("/ApprovalRule/GetLocationByCompanyId?" + param);
  }
  async LoadClientByProjectId(param) {
    return await http.instance.get("/ApprovalRule/LoadClientByProjectId?" + param);
  }
  async ApprovalGroupListByApprovalCHainId(param) {
    return await http.instance.get("/ApprovalRule/GroupList?" + param);
  }
  async GetApprovalGroupByCompanyId() {
    return await http.instance.get("/ApprovalChain/GetApprovalGroupByCompanyId?");
  }
  async AddApprovalChain(param) {  
    return await http.instance.post("/ApprovalChain/AddApprovalChain?" , { postString: param });
  }
  async GetByChainId(param) {
    return await http.instance.get("/ApprovalChain/GetByChainId?" + param);
  }
  async DeleteApprovalChain(param) {    
    return await http.instance.post("/ApprovalChain/DeleteApprovalChain?" + param);
  }
  async AddRule(poststring) {  
    return await http.instance.post("/ApprovalRule/AddRule?" , { postString: poststring });
  }
  async GroupList(param) {
    return await http.instance.get("/ApprovalChain/GroupList?" + param);
  }
  async GetRuleById(param) {
    return await http.instance.get("/ApprovalRule/GetRuleById?" + param);
  }
  async SwapApproverOrder(param) {
    return await http.instance.get("/ApprovalChain/SwapApproverOrderofApprovalGroups?" + param);
  }
  async DeleteApprovalGroup(param) {    
    return await http.instance.post("/ApprovalChain/DeleteApprovalGroupFromApprovalChain?" + param);
  }
  ReportMain(params) {
    return http.instanceCommon.post("/Report/ReportMain", { postString: params });
  }
  async CheckDuplicate(param) {
    return await http.instance.get("/ApprovalChain/CheckDuplicate?" + param);
  }
  async CheckDuplicateGroup(param) {
    return await http.instance.get("/ApprovalGroup/CheckDuplicateGroup?" + param);
  }
  async CheckDuplicateRule(param) {
    return await http.instance.get("/ApprovalRule/ApprovalRuleExist?" + param);
  }
}

export default new DataService();
