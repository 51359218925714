import Vue from "vue";
import VueRouter from "vue-router";
import { storeToRefs } from "pinia";
import { useloggedUserInfo, moduleStore } from "../stores/UserInfo";
//import Callback from '../views/auth/Callback.vue'
import Unauthorized from "../views/auth/Unauthorized.vue";
import authservice from "../service/authservice";
import DataService from "../service/DataService";
Vue.use(VueRouter);
const routes = [
  {
    path: "/logout",
    name: "logout",
    component: Unauthorized,
  },
  {
    path: "/callback",
    name: "callback",
    //component: Callback
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
  },
  // {
  //   path: "/:pathMatch(.*)*",
  //   name: "PageNotFound",
  //   component: () => import("../views/PageNotFound.vue"),
  //   meta: {
  //     title: "Page Not Found",
  //   },
  // },
  {
    path: "/ApprovalRule/test",
    name: "test",
    component: () => import("../views/ApprovalRule/test.vue"),
    meta: {
      checkPrivilege: false,
      title: "ApprovalRule",
    },
  },
  {
    path: "/ApprovalGroup/Index",
    name: "Manage Approval Group",
    component: () => import("../views/ApprovalGroup/ApprovalGroupList.vue"),
    meta: {
      checkPrivilege: false,
      title: "Manage Approval Group",
      breadcrumb: [
        { name: "Approval", link: "/ApprovalRule/index" },
        { name: "Approval Group" },
      ],
    },
  },
  {
    path: "/ApprovalGroup/Add",
    name: "Add Approval Group",
    component: () => import("../views/ApprovalGroup/AddGroup.vue"),
    meta: {
      title: "Add Approval Group",
      checkPrivilege:false,
      breadcrumb: [
        { name: "Approval", link: "/ApprovalRule/index" },
        { name: "Approval Group",link: "/ApprovalGroup/index" },
        { name: "Manage" },
      ],
    },
  },
  {
    path: "/ApprovalRule/Index",
    name: "ApprovalRule",    
    component: () => import("../views/ApprovalRule/ApprovalRuleList.vue"),
    meta: {
      checkPrivilege:false,
      title: "Manage Approval Rule",
      breadcrumb: [
        { name: "Approval", link: "/ApprovalRule/index" },
      ],
    },
  },
  {
    path: "/ApprovalRule/Add",
    name: "ApprovalRuleAdd",    
    component: () => import("../views/ApprovalRule/AddApprovalRule.vue"),
    meta: {
      checkPrivilege:false,
      title: "Add Approval Rule",
      breadcrumb: [
        { name: "Approval", link: "/ApprovalRule/index" },
        { name: "Manage" },
      ],
    },
  },
  {
    path: "/ApprovalReport/Index",
    name: "Approval Report",
    component: () => import("../views/ApprovalReport/Index.vue"),
    meta: {
      checkPrivilege:false,
      title: "Approval Report",
      breadcrumb: [
        {
          name: "Approval Report", link: "/ApprovalReport/Index"
        },
      ],
    },
  },
  {
    path: "/ApprovalChain/Index",
    name: "Approval Chain",
    component: () => import("../views/ApprovalChain/ApprovalChainList.vue"),
    meta: {
      checkPrivilege:false,
      title: "Approval Chain",
      breadcrumb: [{ name: "Approval Chain" }],
    },

  },
  {
    path: "/ApprovalChain/Add",
    name: "Add Approval Chain",
    component: () => import("../views/ApprovalChain/AddApprovalChain.vue"),
    meta: {
      title: "Add Approval Chain",
      checkPrivilege:false,
      breadcrumb: [
        { name: "Approval", link: "/ApprovalRule/index" },
        { name: "Approval Chain", link: "/ApprovalChain/index" },
        { name: "Manage" },
      ],
    },
  },
  {
    path: "/ApprovalChain/GroupList",
    name: "Manage Group",
    component: () => import("../views/ApprovalChain/GroupList.vue"),
    meta: {
      checkPrivilege:false,
      title: "Manage Approval Group",
      breadcrumb: [
        { name: "Approval", link: "/ApprovalRule/index" },
        { name: "Approval Chain", link: "/ApprovalChain/index" },
        { name: "Group List" }],
    },
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const DEFAULT_TITLE = "Home";
router.beforeResolve((to, from, next) => {
  document.title = "TALYGEN - " + (to.meta.title || DEFAULT_TITLE);
  document.getElementById("divProgress1").style.display = "none";
  document.getElementById("talygenfooter").style.display = "block";  
  
  if (
    to.path == "/callback" ||
    to.path == "/unauthorized" ||
    to.path == "/logoutcallback"
  ) {
    next();
  } else {
    if (to.path == "/logout") {
      authservice.logOut();
    } else {
      // if(to.path.indexOf('ContactImportDetails')>-1 && to.path.indexOf('CRM_ACCOUNTS')>-1){
      //   to.meta.breadcrumb=[{name:"Accounts", link:"/CRM/Accounts"},
      //   {name: "Import Account details"}]
      // }else if(to.path.indexOf('ContactImportDetails')>-1 && to.path.indexOf('CRM_CONTACTS')>-1){
      //     to.meta.breadcrumb=[{name:"Contact", link:"/CRM/ContactListing"},
      //     {name: "Import Contacts details"}]
      // }
      const ustore = useloggedUserInfo();
      let app = ustore.GetUserInfo;
      if (app) {
        let isCheckPrivilege = true;
        if(to.meta.hasOwnProperty('checkPrivilege'))
        {
            isCheckPrivilege = to.meta.checkPrivilege;
        }
        if(isCheckPrivilege) {
          let controllerName = "", actionName = "";
          if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
              controllerName = to.meta.controllerName;
              actionName = to.meta.actionName;
          }
          else{
              let pathArray = to.path.split("/");
              if(pathArray.length > 2){
                  controllerName = pathArray[1];
                  actionName = pathArray[2];
              } else{
                  controllerName = pathArray[0];
                  actionName = pathArray[1];
              }
          }
          if(controllerName != "" && actionName != "") {
              // DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
              //     if (value.data)
              //         next()
              //     else
              //         //common.methods.ShowAlert('Sorry, You are not authorized to access this section.', "warning", true, "Alert");
              //         next('/unauthorized');
              //     },
              //     (error) => {
              //         console.log(error);
              //     }
              // );
          } else {
              next()
          }
        } else {
          next();
        }
      } else {
        authservice.authenticate(to.path).then(() => {
          console.log("authenticating a protected url:" + to.path);
          next();
        });
      }
    }
  }
});
export default router;